import React from 'react';
import './styles/Policy.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import policyDirectivesIcon from './assets/images/Icon_directives.png';
import policyGovernanceIcon from './assets/images/Icon_governance.png';
import Bar from './assets/images/bar.png'
import Slideshow from './Slideshow';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function Policy() {
    return (
        <div id="Policy" className="policy-section">
        <Container style={{marginLeft: "7%"}}>
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
        <div className="policy-header">
                    <h2>POLICY</h2>
                <hr className="policy-line1" style={{marginBottom: "9%", marginLeft: "8%"}}/>
                <div className="directives-icon">
                    <p><img src={policyDirectivesIcon} alt="Directives Icon" /> Directives</p>
                    </div>
                </div>
                <p className="directives-paragraph">
                The Department of Defense leadership, across the Air Force, has adopted this course of action to improve our cutting edge technology, accelerate change, and reduce waste while continuously modernizing our mission systems.
                </p>
                <hr className="policy-line2" style={{marginTop: "10%", marginBottom: "9%", marginLeft: "8%"}}/>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
        <Slideshow />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
        <div className="governance-icon">
                    <p><img src={policyGovernanceIcon} alt="Governance Icon" /> Governance</p>
                </div>
                <p className="governance-paragraph">
                Cloud One is governed by several Department of Defense directed actions that define overall requirements for security, development, testing and deployment, as well as reducing waste, and optimizing data.                </p>
                <p className="governance-end">
                *AVAILABLE TO REGISTERED DOD CUSTOMERS
                </p>
                <div className='gov-link'>
                    <img src={Bar} alt="Bar" className="bar-icon" />
                    <a href="https://info.cloudone.af.mil/#/" target="_" rel="noopener noreferrer">Visit our Customer Resource Center for more information</a>
                </div>
        </Grid>
      </Grid>
    </Box>
        </Container>
        </div>
    );
}

export default Policy;