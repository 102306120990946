import React from "react";
import './styles/Footer.css'
import FooterLogo from './assets/images/Cloud One Logo_Cloud Stars_White (Eff Mar 2023)-03.png'

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-columns">
                    <div className="footer-column" style={{marginLeft: "2%"}}>
                        <p>FOIA</p>
                        <p>USA.gov</p>
                        <p>No FEAR Act</p>
                    </div>
                    <div className="footer-column" style={{marginRight: "3%"}} >
                        <p>EEO</p>
                        <p>AF Small Business Support</p>
                        <p>AF Open Government</p>
                    </div>
                    <div className="footer-column" style={{marginRight: "68%"}}>
                        <p>Strategic APR</p>
                        <p>Suicide Prevention</p>
                        <p>SAPR</p>
                    </div>
                </div>
                <div className="footer-logo">
                    <img src={FooterLogo} alt="Cloud One Logo" />
                </div>
                <div className="footer-bottom">
                    <div className="footer-left">
                        <p>Air Force Cloud One is an official website of the Department of Defense</p>
                    </div>
                    <div className="footer-right">
                        <p>Air Force Cloud One Webpage</p>
                        <p>Feedback</p>
                        <p>Accessibility</p>
                        <p>Privacy</p>
                        <p>Security</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;