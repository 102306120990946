import React from "react";
import './styles/Contact.css';
import ButtonIcon1 from './assets/images/Icon_coffee with cloud one-register.png'
import ButtonIcon2 from './assets/images/Icon_coffee with cloud one-linkedin.png'
import { logToMatomo } from './utils/matomoOperations.js'


function Contact() {
    return (
        <div id="Contact" className="contact-section">
            <div id="Connected" className="contact-content" style={{scrollMarginTop: '183px'}}>
                <div className="contact-header">
                    <h2 className="contact-title">LEARN MORE & GET STARTED</h2>
                    <p className="contact-description">
                        To register for your consultation with our Cloud One Kickstart team, we require additional information to bring the right team to the table and discuss your needs, concerns, and next steps on your journey of bringing your fight to the cloud!
                    </p>
                    <div className="contact-buttons">
                        <a href="https://registration.tools.cce.af.mil/registration" onClick={() => logToMatomo(['trackEvent', 'view', 'registration'])} className="contact-button" target="_">
                            <img src={ButtonIcon1} alt="Contact Icon" className="button-icon" />
                            <p className="button1-text">Register your mission system and make an appointment for a free consultation.</p>
                        </a>
                        <a href="https://www.linkedin.com/company/air-force-cloud-one/" onClick={() => logToMatomo(['trackEvent', 'view', 'linkedin'])} className="contact-button" target="_">
                            <img src={ButtonIcon2} alt="Contact Icon" className="button-icon" />
                            <p className="button2-text">We are on Linkedin, follow us today! You can find us at Air Force Cloud One.</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;