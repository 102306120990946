import React, { useState } from 'react';
import './styles/Slideshow.css';
import PolicyImage1 from './assets/images/Policy_slideshow-1.png';
import PolicyImage2 from './assets/images/Policy_slideshow-2.png';
import PolicyImage3 from './assets/images/Policy_slideshow-3.png';
import PolicyImage4 from './assets/images/Policy_slideshow-4.png';
import ArrowRight from './assets/images/arrow-right.svg'
import ArrowLeft from './assets/images/arrow-left.svg'

function Slideshow() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        { image: PolicyImage1, title: '“It is time we put our heads, data, and cutting edge capabilities in Cloud One so that our future Airmen fight with electron speed and lightening bolt power.”', content: 'Assistant Secretary of the Air Force Cloud One & DevSecOps 13 Sep 2019' },
        { image: PolicyImage2, title: '“Cloud One provides a plethora of services that will accelerate the accreditation process, ensure continuing compliance with security controls, and facilitate rapid future deployment of capabilities.”', content: 'Air Force Chief Software Officer Migration Guidance – DAF Enterprise Cloud Services 21 Jun 2021' },
        { image: PolicyImage3, title: '“The DoD Cloud Strategy provides the department unifying guidance to focus cloud computing investments on reducing inefficiencies and accelerating the department’s digital modernization efforts.”', content: 'DoD Chief Information Officer Interim Guidance – Implementation of DoD Cloud Strategy 13 Sept 2019' },
        { image: PolicyImage4, title: '“It is time we put our heads, data, and cutting edge capabilities in Cloud One so that our future Airmen fight with electron speed and lightening bolt power.”', content: 'USAF Chief Information Officer 2022' },
    ];

    const nextSlide = () => {
        // Set current slide's opacity to 0
        document.querySelectorAll(".slide")[currentSlide].style.opacity = 0;
    
        setCurrentSlide((currentSlide + 1) % slides.length);
    
        // Set next slide's opacity to 1
        document.querySelectorAll(".slide")[(currentSlide + 1) % slides.length].style.opacity = 1;
    };
    
    const prevSlide = () => {
        // Set current slide's opacity to 0
        document.querySelectorAll(".slide")[currentSlide].style.opacity = 0;
    
        setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
    
        // Set previous slide's opacity to 1
        document.querySelectorAll(".slide")[(currentSlide - 1 + slides.length) % slides.length].style.opacity = 1;
    };

    return (
        <div className="slideshow-container">
            <div className="slideshow-arrows">
                <button className="prev-arrow" onClick={prevSlide}>
                    <span><img src={ArrowLeft} alt='arrow-left'></img></span>
                </button>
                <button className="next-arrow" onClick={nextSlide}>
                    <span><img src={ArrowRight} alt='arrow-right'></img></span>
                </button>
            </div>
            <div className="slideshow">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`slide ${currentSlide === index ? 'active' : ''}`}
                        style={{ backgroundImage: `url(${slide.image})`, backgroundSize: 'cover' }}
                    >
                        <div className="slide-content">
                            <h3>{slide.title}</h3>
                            <br /><p>{slide.content}</p>
                        </div>
                    </div>
                ))}
                <div className="slideshow-dots">
                    {slides.map((_, index) => (
                        <div
                            key={index}
                            className={`dot ${currentSlide === index ? 'active' : ''}`}
                            onClick={() => setCurrentSlide(index)}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Slideshow;