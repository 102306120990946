import React from 'react';
import './styles/CoffeeWithC1.css';
import CoffeeTitleOverlay from './assets/images/bring-your-fight.png';
import CoffeeCircleImage from './assets/images/Background_coffee with cloud one.png';
import Bar from './assets/images/bar.png'
// import CircleOutline from './assets/images/Background_coffee with cloud one-line.png'; this should be just the outline but it's the image with the outline attached

function CoffeeWithC1() {
    return (
        <div id="CWCO" className='coffee-container'>
            <div className='coffee-background'>
                <div className='coffee-circle'>
                    <img src={CoffeeCircleImage} alt="Coffee Circle" />
                </div>
                <div className='coffee-overlay'>
                    <div className='coffee-title-image'> 
                        <img src={CoffeeTitleOverlay} alt="Coffee Title Overlay" />
                        <div className='coffee-paragraph'>
                            <h2>COFFEE WITH CLOUD ONE</h2>
                            <h3>MISSION & INTEGRATION</h3>
                            <p className='P1'>You're invited! Our Cloud One team hosts Coffee with Cloud One weekly. This is an opportunity for our Air Force and other DoD customers to hear an overview of the Cloud One offering, followed by a Q&A session. Sessions are hosted every Wednesday 10:00-11:00 a.m. EST and Thursday 4:00-5:00 p.m. EST. All are welcome.</p>
                            <div className='bar-text-combo'>
                                <div className='bar1'>
                                    <img src={Bar} alt="Bar" />
                                </div>
                                <div className='T1'>
                                    <p className='P1'>Zoom.us/join</p>
                                    <br /><p className='br1'>TEL: 646-558-8656</p>
                                </div>
                                <div className='Bar2-Full'>
                                    <div className='bar2'>
                                        <img src={Bar} alt="Bar" />
                                    </div>
                                    <div className='T2'>
                                        <p className='P2' style={{width: "100%"}}>Meeting ID: 264 134 9193</p>
                                        <br /><p className='br2'>Password: 369852</p>
                                    </div>
                                </div>
                            </div>
                            <p className='P1' style={{fontSize: "19px", marginTop: "0%"}}>Grab a cup of coffee and learn more about how Cloud One is the solution to delivering fast, agile, secure, and cost effective mission tools to your Mission System!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoffeeWithC1;