import React from 'react';
import './styles/DPAS.css';
import DPASgraphic from './assets/images/Graphic_DPaaS2.png'

function DPAS() {
    return (
        <div id="DPAS" className="dpas-section">
            <div className="dpas-content">
                <div className="dpas-header">
                    <h2>DIGITAL PLATFORM AS A SERVICE</h2>
                    <hr className="dpas-line" />
                </div>
                <div className="dpas-content">
                    <p>
                        The Department of Defense is transforming engineering practices to digital engineering, incorporating technological innovations into an integrated, digital, model-based approach to ensure continued U.S. technological superiority.
                        Embracing the digital engineering transformation, the U.S. Air Force established the Digital Platform as a Service (DPaaS) capability to help you rapidly turn design into reality. DPaaS offers a secure, cloud-based environment established in Cloud One for model-based engineering using digital technologies and tools.
                    </p>
                </div>
                <div className="dpas-image">
                    <img src={DPASgraphic} alt="DPAS Graphic" />
                </div>
            </div>
        </div>
    );
}

export default DPAS;