import './styles/App.css';
import C1Navbar from './navbar.js';
import Home from './Home.js';
import Policy from './Policy.js';
import Benefits from './Benefits';
import Services from './Services';
import DPAS from './DPAS';
import Stories from './Stories';
import CoffeeWithC1 from './CoffeeWithC1';
import Partnerships from './Partnerships';
import Leadership from './Leadership';
import Contact from './Contact';
import Highlights from './Highlights';
import QuickTips from './QuickTips';
import Footer from './footer';

function App() {
  return (
    <div className="App">
      <header >
        <C1Navbar />
      </header>
      <main>
        <Home />
        <Policy />
        <Benefits />
        <Services />
        <DPAS />
        <Stories />
        <CoffeeWithC1 />
        <Partnerships />
        <Leadership />
        <Contact />
        <Highlights />
        <QuickTips />
        <Footer />
        {/* Other components go here */}
      </main>
    </div>
  );
}

export default App;
