import * as React from 'react';
import { Container } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: theme.palette.primary.dark,
//     color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);

// const localStyles = makeStyles({
//   table: {
//     // minWidth: 700,
//   },
// });

/**
 * Acronyms
 * * Reads from /data/acronyms.json and display the acronyms in a table
*/
export default function Acronyms() {

  return (
    <React.Fragment>
      {/* eslint-disable-next-line */}
      <div>
        {/* header */}
        <Container maxWidth="md">
        </Container>
        <Container maxWidth="lg">
          <GetAcronymData />
        </Container>
      </div>
    </React.Fragment>
  );
}

function GetAcronymData() {
  const sourceJsonFile = 'acronyms.json';
  const [acronymData, setAcronyms] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);

  // this was the ultimate helper
  // https://reactjs.org/docs/faq-ajax.html
  React.useEffect(() => {
    fetch(sourceJsonFile, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        setAcronyms(data.acronyms);
        console.log("original data in fetch: ");
        console.log(data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      });
  }, [sourceJsonFile]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <React.Fragment>
        <TableCustomized rows={acronymData} />
      </React.Fragment>
    );
  }
}

function TableCustomized(props) {
  const { rows } = props;

  return (
    <TableContainer component={Container} maxWidth="lg">
      <Table aria-label="customized table" style={{color: "white", fontSize: "8.5px", padding: "0"}}>
        <TableHead style={{color: "white"}}>
          <TableRow>
            <TableCell style={{color: "white", fontSize: "8.5px", padding: "0"}}>Name</TableCell>
            <TableCell style={{color: "white", fontSize: "8.5px", padding: "0"}}>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{color: "white", fontSize: "8.5px", padding: "0"}}>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" style={{color: "white", fontSize: "8.5px", padding: "0"}}>
                {row.acronym}
              </TableCell>
              <TableCell style={{color: "white", fontSize: "8.5px", padding: "5"}}>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
