import React, { useState } from 'react';
import './styles/Partnerships.css';

import Partner1 from './assets/images/partners/kessel.png';
import Partner2 from './assets/images/partners/bespin.png';
import Partner3 from './assets/images/partners/level.png';
import Partner4 from './assets/images/partners/dafc.png';
import Partner5 from './assets/images/partners/p1.png';
import Partner6 from './assets/images/partners/tron.png';
import Partner7 from './assets/images/partners/ski.png';
import Partner8 from './assets/images/partners/fence.png';
import { logToMatomo } from './utils/matomoOperations.js'


// import for styling component
import { styled } from '@mui/material/styles';
// imports from Material UI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from 'react-bootstrap/Modal';


function Partnerships() {
  const [show, setShow] = useState(false);
  const [textModal, setTextModal] = useState('');
  const [nameModal, setNameModal] = useState('');
  const [linkModal, setLinkModal] = useState('');
  const handleClose = () => setShow(false);

  const Item = styled(Paper)(({ theme }) => ({
    justifyContent: "centre",
    alignItems: "centre", //centring text 
    color: theme.palette.text.primary, // black
    height: 250,
    width: 250, 
    display: "flex", // flex display inside panels
    marginBottom: 0,
    marginRight: 10,
    marginLeft: 10,
    background: "white",
  }));

  function fillModal(partner) {
    if (partner === 'kessel') {
      logToMatomo(['trackEvent', 'view', 'Kessel Run']);
      setNameModal('Kessel Run');
      setTextModal('Kessel Run has the honor of being the first modern software factory in the Department of Defense. We offer an end-to-end solution to deliver combat capability that can sense and respond to conflict in any domain, any time, anywhere. In order to deliver that capability, we embrace an organizational culture guided by the following our cores values: ideas over rank, intense customer focus, bias for action, and continuous evolution.');
      setLinkModal('https://kesselrun.af.mil/');
    }  else if (partner === 'bespin') {
      logToMatomo(['trackEvent', 'view', 'Bespin']);
      setNameModal('Bespin');
      setTextModal('BESPINs mission is to use research, design, and technology to help organizations create exceptional experiences and enduring solutions for Airmen. As the United States Air Forces mobile center of excellence, we deliver exceptional user experiences across both native and responsive applications. Everything we do is focused on enabling our community of customers, Airmen, vendors, and others to achieve digital mastery. We coach, train, mentor, and educate the next generation of digital service professionals.');
      setLinkModal('https://teambespin.us/');
    } else if (partner === 'level') {
      logToMatomo(['trackEvent', 'view', 'LevelUp']);
      setNameModal('LevelUp');
      setTextModal('LevelUp is the cyber factory team for the Air Force and is the team behind Platform One, the centralized team that provides DevSecOps/Software Factory managed services with baked-in security to Air Force and Department of Defense programs. The team will provide the ability to deploy a DevSecOps Platform (CNCF-compliant Kubernetes stack) and Continuous Integration/Continuous Delivery (CI/CD) pipeline with a Continuous Authority to Operate (c-ATO).');
      setLinkModal('https://noisytrumpet.com/our-work/levelup-code-works/');
    } else if (partner === 'daf') {
      logToMatomo(['trackEvent', 'view', 'DAF CLOUDworks']);
      setNameModal('DAF CLOUDworks');
      setTextModal('DAF CLOUDworks (DAF Cw) is the cloud management office to enable the Department of the Air Force (DAF) Intelligence, Surveillance, and Reconnaissance (ISR) enterprise towards cloud adoption. We offer IaaS+, PaaS, and SaaS cloud-based solutions (including secured cloud-based containerized Dev and Test pipelines) with enterprise services to our customers at Unclassified, Secret and Top Secret//SCI enclaves. This includes ODIN secured reciprocity agreements with Platform One and NGA SWAPP, which enable mission applications that have graduated from those pipelines to be hosted in classified cloud environments. We offer continuous Authority to Operate (cATO), so applications only need to concern themselves with Certificate to Field (CtF). For customers not-yet ready for containerization, our IaaS+ environment supports customers’ Virtual Private Cloud (VPC) infrastructure with enterprise and security services to assist in meeting ATO requirements. DAF Cw engages with customers from start to finish to ensure mission owners have the information needed to plan, resource, and budget for their cloud journey.');
      setLinkModal('https://intelshare.intelink.gov/sites/dafcloudworks/SitePages/Home.aspx');
    } else if (partner === 'p1') {
      logToMatomo(['trackEvent', 'view', 'Platform One']);
      setNameModal('Platform One');
      setTextModal('Platform One is the centralized team executing the DoD DevSecOps Initiative which provides DevSecOps/Software Factory managed services with baked-in security to Air Force and Department of Defense programs. The team provides the ability to deploy a DevSecOps Platform (CNCF-compliant Kubernetes stack) and Continuous Integration/Continuous Delivery (CI/CD) pipeline with a Continuous Authority to Operate (c-ATO).');
      setLinkModal('https://p1.dso.mil/');
    } else if (partner === 'tron') {
      logToMatomo(['trackEvent', 'view', 'TRON']);
      setNameModal('TRON');
      setTextModal('Our Airmen are part of a digitally-native generation, yet do not have access to the data systems and software needed to work in a modern era. Many squadron processes are manual, with data input, retrieval, and export done via copy/paste, Word, Excel, or .pdf files. The result: DoD loses rapid flexibility or decision latitude that could have been used to gain the decisive edge in a battle while our Airmen lose their work-life balance. We believe that, through a combination of proper training, mentorship, and the availability of proper infrastructure/tooling, it will be possible to generate an inner-source network of code and talent necessary to remove software development stovepipes for military, civilian, and contractor DoD teams. This allows warfighters to refocus time on their mission sets while instilling the digital experience and knowledge to develop effective future DoD leaders. Providing organic training within the DoD, the resources and tools to develop, and the ability to rapidly deploy applications to those within the DoD embody the mission of the TRON project, We fight for the Warfighter!');
      setLinkModal('https://tron.dso.mil/');
    } else if (partner === 'ski') {
      logToMatomo(['trackEvent', 'view', 'SkiCAMP']);
      setNameModal('SkiCAMP');
      setTextModal('We are a Platform1 software factory located in Salt Lake City, Utah, focused on the continuous development of Hill AFB mission applications to the warfighter.');
      setLinkModal('');
    } else if (partner === 'fence') {
      logToMatomo(['trackEvent', 'view', 'FENCES']);
      setNameModal('FENCES');
      setTextModal('Fences provides Mission Owners the ability to leverage Amazon Commercial Cloud Services (C2S) for SAP and SCI data processing. Mission Owners are able to focus on managing their mission systems and applications, while Fences provides the underlying infrastructure and IT Services. Fences expedites capability deployment and authorization, provides access to on-demand and unlimited storage and compute resources, and allows mission owners to autonomously manage their computing environment with absolute control without having to worry about physical infrastructure. Fences replaces the traditional, protracted procurement lifecycle by providing an accredited enterprise Platform as a Service (PaaS) enabling mission owners to deploy secure, compartmented IT systems to their users in weeks, rather than years.');
      setLinkModal('');
    }
    
    setShow(true);
  }

  return (
    <div id="Partnership" className="partnerships-container">
      <div className='partnerships-header'>
        <div className="section-title1">MEET OUR TEAM</div>
        <div className="sub-title1">Our Partners</div>
        <p className="partners-text">
          Cloud One has partnered with DoD's top software development programs
          to aid in supporting your mission needs.
        </p>
      </div>
      <div className="partner-logo">
      <Box sx={{ flexGrow: 1 }}>
    {/* Grid layout container with breakpoints */}
      <Grid container >
              {/* Grid item with breakpoints*/}
          <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('kessel')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner1}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
        </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('bespin')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner2}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
        </Item>
          </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('level')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner3}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
            </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('daf')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner4}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
            </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('p1')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner5}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
            </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('tron')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner6}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
            </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} key={1} >
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('ski')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner7}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
            </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
            <Item elevation={3} style={{width: "100%", cursor: "pointer"}} className="partner-block" onClick={() => fillModal('fence')}>
            {/* text to be shown inside panel */}
            <img
              alt="partner logo"
              className="partnerImage"
              src={Partner8}
              style={{ width: "80%", height: "80%", margin: "10%"}}
            />
            </Item>
        </Grid>
      </Grid>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="partner-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{nameModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {textModal}
        </Modal.Body>
        <Modal.Footer>
        {(nameModal !== "SkiCAMP" && nameModal !== "FENCES") &&
          <a href={linkModal} style={{ textDecoration: "none"}} target="_">LEARN MORE</a>
                      } 
        </Modal.Footer>
      </Modal>
      </Box>

      </div>
    </div>
  );
}

export default Partnerships;