import React from 'react'
import BenefitsHeader from './assets/images/Text_header-benefits.png'
import './styles/Benefits.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


function Benefits() {
    return (
        <div id="Benefits" className="benefits-section">
            <div className="benefits-full">
            <img className="header-image" src={BenefitsHeader} alt="Benefits Header" />
            <div className="text-container">
            <Grid item xs={12} sm={12} md={12}>
                <div className="left-text">
                <Grid item xs={12} sm={12} md={12}>
                    <div>
                        <h4>Common Computing Environment</h4>
                        <p>Cloud One provides the guardrails to the cloud in a standard manner that allows you to focus on your mission.</p>
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div>
                        <h4 style={{paddingTop: '1vh'}}>Fully Automated</h4>
                        <p>All environmental stand-up is managed by automation scripts that drastically speed up deployment, reduce manual work and human errors.</p>
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div>
                        <h4 style={{marginTop: '-1vh'}}>Single, Federated & MFA Security Tier </h4>
                        <p>There is one login across all access - login points, permitting one user to leverage all management applications; no secondary logins or non-elevated machine accounts. Our process is fully audited for all management activities.</p>
                    </div>
</Grid>
                </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <div className="center-text">
                    <h4>DEVSECOPS Focused</h4>
                    <p>Secure - mission driven deployments are built into the framework to ensure self-service and seamless deployments</p>
                </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <div className="right-text">
                
                    <div>
                        <h4>Computer Aided Software Testing Tool</h4>
                        <p>CAST Highlight is a cloud scanning option used to check the overall health of your application. MSOs can take advantage of this service by supplying mission software.</p>
                    </div>
                    <div>
                        <h4 style={{paddingTop: '1vh'}}>Proactive Scaling & System Monitoring</h4>
                        <p>Mission System Owners (MSOs) can see all operational metrics and provide rules and alerts to manage each mission their way.</p>
                    </div>
                    <div>
                        <h4 style={{paddingTop: '4vh'}}>Accreditation Inheritance</h4>
                        <p>Accreditation Inheritance has been identified in the AF Cloud One eMASS accounts through our Government Cloud Service Providers to include inheritance from the CSP, USAF, DoD, and CSSP; what remains are the unique mission application level controls</p>
                    </div>
                </div>
                </Grid>
            </div>
</div>
            <Box sx={{ flexGrow: 1 }} className="benefits-mobile">
      <Grid container >
      <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                        <h1>BENEFITS</h1>
                    </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                        <h4>Common Computing Environment</h4>
                        <p>Cloud One provides the guardrails to the cloud in a standard manner that allows you to focus on your mission.</p>
                    </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                        <h4 >Fully Automated</h4>
                        <p>All environmental stand-up is managed by automation scripts that drastically speed up deployment, reduce manual work and human errors.</p>
                    </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                        <h4 style={{}}>Single, Federated & MFA Security Tier </h4>
                        <p>There is one login across all access - login points, permitting one user to leverage all management applications; no secondary logins or non-elevated machine accounts. Our process is fully audited for all management activities.</p>
                    </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                    <h4>DEVSECOPS Focused</h4>
                    <p>Secure - mission driven deployments are built into the framework to ensure self-service and seamless deployments</p>
                </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                        <h4>Computer Aided Software Testing Tool</h4>
                        <p>CAST Highlight is a cloud scanning option used to check the overall health of your application. MSOs can take advantage of this service by supplying mission software.</p>
                    </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                        <h4 style={{}}>Proactive Scaling & System Monitoring</h4>
                        <p>Mission System Owners (MSOs) can see all operational metrics and provide rules and alerts to manage each mission their way.</p>
                    </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
          <div>
                        <h4 style={{}}>Accreditation Inheritance</h4>
                        <p>Accreditation Inheritance has been identified in the AF Cloud One eMASS accounts through our Government Cloud Service Providers to include inheritance from the CSP, USAF, DoD, and CSSP; what remains are the unique mission application level controls</p>
                    </div>
          </Grid>
          </Grid>
          </Box>
        </div>
    );
}

export default Benefits;