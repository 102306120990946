import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavLogo from "./assets/images/c1-logo-tagline-multi-dark-hd.png"
import { logToMatomo } from './utils/matomoOperations.js'

import "bootstrap/dist/css/bootstrap.min.css";
import './styles/Navbar.css';
import './styles/fonts.css';


function C1Navbar() {
    const [showAboutDropdown, setShowAboutDropdown] = useState(false);
    const [showWWODropdown, setShowWWODropdown] = useState(false);
    const [showNSCDropdown, setShowNSCDropdown] = useState(false);

    const handleDropdownMouseEnter = (setter) => {
        setter(true);
    };

    const handleDropdownMouseLeave = (setter) => {
        setter(false);
    };

    return (
        <Navbar expand="lg" className="navbar-custom">
            <Navbar.Brand href="#">
                <img
                    src={NavLogo}
                    width="102"
                    height="46"
                    alt="NavLogo"
                    className="d-inline-block align-top ms-4"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="C1navbar">
                <Nav className="ms-5 mt-3 justify-content-center">
                    <NavDropdown 
                        show={showAboutDropdown}
                        onMouseEnter={() => handleDropdownMouseEnter(setShowAboutDropdown)}
                        onMouseLeave={() => handleDropdownMouseLeave(setShowAboutDropdown)}
                        title="About" 
                        id="about-dropdown" 
                        drop="down" 
                        style={{ fontFamily: 'HelveticaNeueLTStd-Bd' }} 
                        className='me-4'
                    >
                        <NavDropdown.Item href="#Home" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Our Program'])}>Our Program</NavDropdown.Item>
                        <NavDropdown.Item href="#Policy" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Policy'])}>Policy</NavDropdown.Item>
                        <NavDropdown.Item href="#Benefits" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Benefits'])}>Benefits</NavDropdown.Item>
                        <NavDropdown.Item href="#Stories" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Success Stories'])}>Success Stories</NavDropdown.Item>
                        <NavDropdown.Item href="#Partnership" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Partnerships'])}>Partnerships</NavDropdown.Item>
                        <NavDropdown.Item href="#Leadership" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Leadership'])}>Leadership</NavDropdown.Item>
                        <NavDropdown.Item href="#Contact" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Contact Us'])}>Contact Us</NavDropdown.Item>
                        <NavDropdown.Item href="#Highlights" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Professional Highlights'])}>Professional Highlights</NavDropdown.Item>
                        <NavDropdown.Item href="#QuickTips" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Quick Tips'])}>Quick Tips</NavDropdown.Item>
                        {/* <NavDropdown.Item href="https://cloudone.af.mil/#/acronym" target="_" rel="noopener noreferrer" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }}>Acronyms</NavDropdown.Item> */}
                    </NavDropdown>
                    <NavDropdown 
                        show={showWWODropdown}
                        onMouseEnter={() => handleDropdownMouseEnter(setShowWWODropdown)}
                        onMouseLeave={() => handleDropdownMouseLeave(setShowWWODropdown)}
                        title="What We Offer" 
                        id="WWO-dropdown" 
                        style={{ fontFamily: 'HelveticaNeueLTStd-Bd' }}
                        className='me-4'
                    >
                        <NavDropdown.Item href="#Services" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Services'])}>Services</NavDropdown.Item>
                        <NavDropdown.Item href="#Offerings" style={{ fontFamily: 'HelveticaLTStd-Roman' }} className="submenu-item" onClick={() => logToMatomo(['trackEvent', 'view', 'Service Offerings'])}>Service Offerings</NavDropdown.Item>
                        <NavDropdown.Item href="#Development" style={{ fontFamily: 'HelveticaLTStd-Roman' }} className="submenu-item" onClick={() => logToMatomo(['trackEvent', 'view', 'Development'])}>Development</NavDropdown.Item>
                        <NavDropdown.Item href="#Migration" style={{ fontFamily: 'HelveticaLTStd-Roman' }} className="submenu-item" onClick={() => logToMatomo(['trackEvent', 'view', 'Migration Options'])}>Migration Options</NavDropdown.Item>
                        <NavDropdown.Item href="#DPAS" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'igital Platform as a Service<'])}>Digital Platform as a Service</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown 
                        show={showNSCDropdown}
                        onMouseEnter={() => handleDropdownMouseEnter(setShowNSCDropdown)}
                        onMouseLeave={() => handleDropdownMouseLeave(setShowNSCDropdown)}
                        title="Next Steps & Consultation" 
                        id="NSC-dropdown" 
                        style={{ fontFamily: 'HelveticaNeueLTStd-Bd' }}
                        className='me-4'
                    >
                        <NavDropdown.Item href="#CWCO" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Coffee With Cloud One'])}>Coffee With Cloud One</NavDropdown.Item>
                        <NavDropdown.Item href="https://registration.tools.cce.af.mil/registration" target="_" rel="noopener noreferrer" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Application Registration'])}>Application Registration</NavDropdown.Item>
                        <NavDropdown.Item href="#Connected" style={{ fontFamily: 'HelveticaNeueLTStd-Md' }} onClick={() => logToMatomo(['trackEvent', 'view', 'Get Connected'])}>Get Connected</NavDropdown.Item>
                    </NavDropdown>
                    
                    <Nav.Link 
                        href="https://portal.tools.cce.af.mil/home" 
                        style={{ fontFamily: 'HelveticaNeueLTStd-Bd' }}
                        className='nav-link1'
                        onClick={() => logToMatomo(['trackEvent', 'view', 'Customer Resource Center (CAC Enabled)'])}
                    >
                        Customer Resource Center (CAC Enabled)
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default C1Navbar;