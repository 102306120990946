import React from 'react';
import './styles/QuickTips.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import policyDirectivesIcon from './assets/images/Icon_directives.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Acronyms from './Acronyms';
import { logToMatomo } from './utils/matomoOperations.js'


function QuickTips() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true);
      logToMatomo(['trackEvent', 'view', 'Acronyms']);
    };

    return (
        <div id="QuickTips" className="quicktips-section">
        <Container>
            <Row>
                <Col xs={12}>
                <div className="quicktips-header">
                    <h2>Quick Tips</h2>
                <div className="q-icon">
                    <img src={policyDirectivesIcon} alt="Governance Icon" />
                    <p><span className="quicktips-link" style={{cursor: "pointer"}} onClick={handleShow} target="_" rel="noopener noreferrer"><u>Acronyms List</u></span></p>
                    </div>
                </div>
                <p className="q-paragraph">
                Acronyms are widely used and often misunderstood in our industry. Although they serve as a shorthand way of communicating complex ideas and can help to streamline communication and improve efficiency. They can be confusing or obscure for those who are not familiar.
                </p>
                <p className="q-paragraph" style={{marginTop: "20px"}}>
                In order to provide context and clarity when using acronyms to ensure effective communication, we have added a list of <span className="quicktips-link" style={{cursor: "pointer"}} onClick={handleShow} target="_" rel="noopener noreferrer"><u>commonly used terms</u></span>
                </p>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{color: "white"}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Acronyms</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: "white"}}>
            <Acronyms />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
                </Col>
            </Row>
        </Container>
        </div>
    );
}

export default QuickTips;