import React from "react";
import './styles/Highlights.css';
import ThumbnailArticle1 from './assets/images/thumbnail_150.png';
import ThumbnailArticle2 from './assets/images/thumbnail-article-1.webp';
import ThumbnailArticle3 from './assets/images/thumbnail-article-2.webp';
import { logToMatomo } from './utils/matomoOperations.js'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Highlights() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        }
    };
    return (
        <div id="Highlights" className="highlights-container">
            <h2 className="highlights-title">PROFESSIONAL HIGHLIGHTS</h2>
            <div style={{maxWidth: '900px', marginLeft: 'auto', marginRight: 'auto'}}>
            <Carousel
                showDots={false}
                responsive={responsive}
                infinite={true}
                centerMode={false}
                arrows={true}
            >
               <div className="highlight" >
                    <img src={ThumbnailArticle1} alt="Article 1 Thumbnail" style={{width: '400px', height: '300px'}} />
                    <h3 className="highlight-title">Cloud One expands in midst of Great Power Competition</h3>
                    <p className="highlight-description" style={{ width: '100%', paddingBottom: "5%" }}>Cloud One, chartered by the Air Force Life Cycle Management Center, paved the way for the Department of the Air Force to become a leader in cloud hosting.</p>
                    <a className="learn-more-link" href="https://www.hanscom.af.mil/News/Article-Display/Article/3903783/cloud-one-expands-in-midst-of-great-power-competition/" onClick={() => logToMatomo(['trackEvent', 'view', 'fedscoop'])} target="_">LEARN MORE</a>
                </div>
                <div className="highlight">
                    <img src={ThumbnailArticle2} alt="Article 1 Thumbnail" style={{width: '400px', height: '300px'}} />
                    <h3 className="highlight-title">Cloud One: Taking aim at the enemy, one application at a time</h3>
                    <p className="highlight-description" style={{ width: '100%', paddingBottom: "5%" }}>From GEARFIT to the Special Warfare Technical Integration Support Center, the Cloud One team shares how its platform is supporting mission-critical applications for the Air Force.</p>
                    <a className="learn-more-link" href="https://fedscoop.com/cloud-one-taking-aim-at-the-enemy-one-application-at-a-time/" onClick={() => logToMatomo(['trackEvent', 'view', 'fedscoop'])} target="_">LEARN MORE</a>
                </div>
                <div className="highlight">
                    <img src={ThumbnailArticle3} alt="Article 3 Thumbnail" style={{width: '400px', height: '300px'}} />
                    <h3 className="highlight-title">Cloud One: Enabling Cloud For Almost Any Department of Defense Use Case</h3>
                    <p className="highlight-description" style={{ width: '100%', paddingBottom: "5%" }}>Cloud One facilitates faster and more cost-effective cloud migrations, accelerating digital transformation across the organization and enabling global connectivity and data accessibility.</p>
                    <a className="learn-more-link" href="https://www.airandspaceforces.com/cloud-one-enabling-cloud-for-almost-any-department-of-defense-use-case/" onClick={() => logToMatomo(['trackEvent', 'view', 'air and space'])} target="_">LEARN MORE</a>
                </div> 
            </Carousel>
            </div>
         
            <div className="highlights-square">
                {/* <div className="highlight">
                    <img src={ThumbnailArticle1} alt="Article 1 Thumbnail" style={{width: '400px', height: '300px'}} />
                    <h3 className="highlight-title">Cloud One expands in midst of Great Power Competition</h3>
                    <p className="highlight-description" style={{ width: '100%', paddingBottom: "5%" }}>Cloud One, chartered by the Air Force Life Cycle Management Center, paved the way for the Department of the Air Force to become a leader in cloud hosting.</p>
                    <a className="learn-more-link" href="https://www.hanscom.af.mil/News/Article-Display/Article/3903783/cloud-one-expands-in-midst-of-great-power-competition/" onClick={() => logToMatomo(['trackEvent', 'view', 'fedscoop'])} target="_">LEARN MORE</a>
                </div>
                <div className="highlight">
                    <img src={ThumbnailArticle2} alt="Article 1 Thumbnail" style={{width: '400px', height: '300px'}} />
                    <h3 className="highlight-title">Cloud One: Taking aim at the enemy, one application at a time</h3>
                    <p className="highlight-description" style={{ width: '100%', paddingBottom: "5%" }}>From GEARFIT to the Special Warfare Technical Integration Support Center, the Cloud One team shares how its platform is supporting mission-critical applications for the Air Force.</p>
                    <a className="learn-more-link" href="https://fedscoop.com/cloud-one-taking-aim-at-the-enemy-one-application-at-a-time/" onClick={() => logToMatomo(['trackEvent', 'view', 'fedscoop'])} target="_">LEARN MORE</a>
                </div>
                <div className="highlight">
                    <img src={ThumbnailArticle3} alt="Article 3 Thumbnail" style={{width: '400px', height: '300px'}} />
                    <h3 className="highlight-title">Cloud One: Enabling Cloud For Almost Any Department of Defense Use Case</h3>
                    <p className="highlight-description" style={{ width: '100%', paddingBottom: "5%" }}>Cloud One facilitates faster and more cost-effective cloud migrations, accelerating digital transformation across the organization and enabling global connectivity and data accessibility.</p>
                    <a className="learn-more-link" href="https://www.airandspaceforces.com/cloud-one-enabling-cloud-for-almost-any-department-of-defense-use-case/" onClick={() => logToMatomo(['trackEvent', 'view', 'air and space'])} target="_">LEARN MORE</a>
                </div> */}

            </div>
        </div>
    );
}

export default Highlights;